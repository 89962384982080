<template>
  <div>
    <!-- uploadC9A809AE-5B68-4F14-9DF8-147533D9D03B.swf -->
    <!-- pic024E9B01329F40A824DBDF3FFAF56270.jpg -->
    <img class="img-large rounded" src="">
    <p>
      Would you enjoy the glory of getting your model made into a LEGO Universe monument? Now is your chance to build a model that could wow LEGO Universe minifigures for years to come!
    </p>
    <p>
      Hurry to the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '909B728D-A1CC-4176-8F05-00655A65AF63'} }">Creation Lab</router-link> to submit your design for a sculpture or statue that celebrates the power of imagination! One lucky builder's name and creation will be publicly displayed in LEGO Universe!
    </p>
  </div>
</template>
